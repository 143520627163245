import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'slideout-console',
  templateUrl: './slideout-console.component.html',
  styleUrls: ['./slideout-console.component.scss'],
})
export class SlideoutConsoleComponent implements OnInit {
  isCollapsed = true;
  postMessageData: object[] = [];
  numberTest = 1;

  constructor() {}

  ngOnInit(): void {
    window.addEventListener('message', this.receiveMessage, false);
  }

  toggleSize() {
    this.isCollapsed = !this.isCollapsed;
  }

  clearConsole() {
    this.postMessageData = [];
  }

  receiveMessage = (event: MessageEvent) => {
    const origin: string = event.origin;
    const containsML3DS =
      (!origin.includes('ml3ds-tools') && origin.includes('ml3ds')) ||
      origin.includes('medialab');

    const fromLocalHostButNotThisOne =
      origin.includes('localhost') && !origin.includes('localhost:4200');

    if (origin && (containsML3DS || fromLocalHostButNotThisOne)) {
      let eventData = event.data;
      if (typeof eventData === 'string') {
        eventData = JSON.parse(eventData);
      }
      const postMsg: object = { data: eventData, origin: event.origin };
      this.postMessageData.unshift(postMsg);
    }
  };

  sendPostMessage(message) {
    console.log(message);
    let iframe = document?.getElementById('iframe') as HTMLIFrameElement;
    iframe.contentWindow.postMessage(message, '*');
  }
}
