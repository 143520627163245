import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ResizeObserver } from 'resize-observer';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'MediaLab iFrame Tool';
  url = '';
  urlSafe: SafeResourceUrl;
  height = 600;
  width = 800;
  urlHistory: string[] = this.getUrlLocalStorage();

  constructor(public sanitizer: DomSanitizer) {}

  @ViewChild('urlInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  private myObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      const observeHeight = Math.round(entry.contentRect.height);
      const observeWidth = Math.round(entry.contentRect.width);
      if (observeHeight !== this.height) {
        this.height = observeHeight;
      }
      if (observeWidth !== this.width) {
        this.width = observeWidth;
      }
    });
  });

  private observer = () => {
    const container = document.querySelector('.resizable-container');
    this.myObserver.observe(container);
  };

  close() {
    this.autoComplete.closePanel();
  }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.width = window.innerWidth * 0.8;
    this.height = window.innerHeight * 0.6;
    this.observer();
  }

  clearUrl() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  updateUrl(incomingUrl) {
    this.url = incomingUrl;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.setUrlLocalStore(incomingUrl);
    const iframe = document.getElementById('iframe');
    iframe.onload = () => {
      if (this.url !== iframe.getAttribute('src')) {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
      const urlInput = document.getElementById('urlInput');
      urlInput.blur();
      this.close();
    };
  }

  getUrlLocalStorage() {
    const urlObject = JSON.parse(localStorage.getItem('urlHistory'));
    const urlArray: string[] = urlObject ? urlObject.urls : [];
    return urlArray.length > 0 ? urlArray : [];
  }
  setUrlLocalStore(url: string) {
    if (url) {
      const storedUrls: string[] = this.getUrlLocalStorage();
      const filterResult: string[] = storedUrls.filter(array => array !== url);
      filterResult.unshift(url);
      const slicedUrls = filterResult.slice(0, 15);
      const urls = { urls: slicedUrls };
      localStorage.setItem('urlHistory', JSON.stringify(urls));
    }
  }
}
