<div fxLayout="column" class="header-section">
  <mat-toolbar class="title-toolbar" color="primary">
    <mat-toolbar-row>
      <span> {{ title }} </span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div fxFlex>
    <div fxLayout="row wrap" class="options-container">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" class="resolution-selector">
          <mat-label>Width</mat-label>
          <input
            matInput
            #widthInput
            [(width)]="width"
            placeholder="Width"
            type="number"
            value="{{ width }}"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="resolution-selector">
          <mat-label>Height</mat-label>
          <input
            matInput
            #heightInput
            [(height)]="height"
            placeholder="Height"
            type="number"
            value="{{ height }}"
          />
        </mat-form-field>
      </div>
      <div class="url-container" fxLayout="row" fxFlex fxLayoutAlign=" center">
        <mat-form-field id="formField" fxFlex="90" class="url-input-field">
          <input
            id="urlInput"
            matInput
            [matAutocomplete]="auto"
            placeholder="URL"
            #urlInput
            (keyup.enter)="updateUrl(urlInput.value)"
            type="text"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option              
              (click)="updateUrl(urlInput.value)"
              *ngFor="let url of getUrlLocalStorage()"
              [value]="url"
            >
              {{ url }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button
          #urlButton
          mat-raised-button
          (click)="updateUrl(urlInput.value)"
        >
          {{ urlInput.value && urlInput.value === url ? 'Refresh' : 'Enter' }}
        </button>
      </div>
    </div>
  </div>
</div>

<div id="main-content" class="main-content" > 
  <div
    class="resizable-container"
    [style.width.px]="widthInput.value"
    [style.height.px]="heightInput.value"
  >
    <iframe
      id="iframe"
      frameBorder="0"
      [src]="urlSafe"
      [style.width.px]="width"
      [style.height.px]="height"
    ></iframe>
  </div>
  <slideout-console fxLayout="row" fxLayoutAlign="center end"></slideout-console>
</div>
