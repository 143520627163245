<div class="console" [ngClass]="{ collapsed: isCollapsed }">
  <button class="toggle" (click)="toggleSize()" #toggleButton mat-raised-button>
    Post Messaging
  </button>

  <div class="console-content">
    <mat-tab-group>
      <mat-tab label="Received Messages">
        <mat-list dense>
          <div class="console-header">
            <h3 mat-subheader>Received Messages</h3>
            <button mat-raised-button color="warn" (click)="clearConsole()">
              Clear Log
            </button>
          </div>
          <mat-list-item *ngFor="let event of postMessageData">
            <button cdkCopyToClipboard="{{ event.data | json }}">
              <mat-icon mat-list-icon>content_copy</mat-icon>
            </button>
            <div mat-line>
              <h4>{{ event.origin }}</h4>
              <pre> {{ event.data | json }} </pre>
              <mat-divider></mat-divider>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-tab>
      <mat-tab label="Send Message">
        <form class="sendform">
          <mat-form-field class="sendFormField" appearance="fill">
            <mat-label>Send postMessage to Iframe</mat-label>
            <textarea
              #postMessageInput
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="12"
            ></textarea>
            <mat-hint>Input Valid JSON</mat-hint>
          </mat-form-field>
          <button
            mat-raised-button
            color="warn"
            (click)="sendPostMessage(postMessageInput.value)"
          >
            Send
          </button>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
